import { styled } from "@mui/material/styles";
import { Box, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
// import Stack from '@mui/material/Stack';
// import Switch from '@mui/material/Switch';
import Cookies from "universal-cookie";
import _ from "lodash";
import { useSiteDataApi } from "../utilities/SiteDataProvider";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const ZoomReport = () => {
  const [siteDataContext] = useSiteDataApi();
  const [callStats, setCallStats] = useState({});
  const cookies = new Cookies();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");

  const [groupedZoomData, setGroupedZoomData] = useState([]);

  useEffect(() => {
    const fetchCallLogs = async () => {
      try {
        const response = await fetch("/api/call-logs", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": siteDataContext.thhcsfrtoken,
          },
        });
        //response is json, decode it
        let data = await response.json();
        setCallStats(data);

        console.log("response", data);
        setCallStats(data);
      } catch (error) {
        console.error("Error fetching call logs:", error);
      }
    };

    fetchCallLogs();

    // Poll every 2 minutes
    const interval = setInterval(fetchCallLogs, 600000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    async function fetchData() {
      await fetchzoomData();
    }
    fetchData();
  }, []);
  const namesWeCareAbout = [
    "Brittany Bossence",
    "Mary Abel",
    "Sara Taba",
    "Monica Arthofer",
    "Megan Behn",
    "Jessica Chester",
    "Joshua Wright",
    "Kaylee Potts",
    "Laura Coates",
    "Julia Janusz",
    // "Brian Field",
    // "True Hearing Health",
    // "Taylor Field",
    "Kayla Belanger",
    "Milka Gabretansea",
    "Kelly Fenn",
    "Christine DeLuca",
    "Ivett Bodi",
  ];
  //sort namesWeCareAbout alphabetically
  namesWeCareAbout.sort();

  const fetchzoomData = async () => {
    const res = await fetch("/api/zoom-data", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": siteDataContext.thhcsfrtoken,
      },
    });

    let data = await res.json();
    if (data[0]) {
      console.log("zoom data", data[0]);
      //loop through the data[0] and remove any items where result = "No Answer"
      // data[0] = data[0].filter((item) => item.result !== "No Answer");

      // setZoomData(data[0]);
      //analyze the zoomData and group it by caller_name or calee_name, either one of those fields should match one of the following names:
      // 'Brittany Bossence', 'Alex Giannos', 'Sara Taba', 'Monica Fisher'
      //filter out the other names

      //create a new array which contains 4 objects, one for each of the above names, inside it contains all the call logs for that person. it also includes the number of calls, and the average call length. the calls on the 2nd level should be grouped by week starting from monday using moment. callee_name and caller_name can be merged into one field, call_name - whichever preson is not in the above list of 4 names should be the call_name value.
      //the data should look like this:
      // [
      //   {
      //     name: 'Brittany Bossence',
      //     calls: [
      //       {
      //         week: '2021-10-04',
      //         calls: [
      //           {
      //             call_name: 'Aiken, Linda [CHA]',
      //             direction: 'inbound',
      //             result: 'Call Cancel',
      //             path: 'pstn',
      //             callee_did_number: '+12263122126',
      //             duration: 0
      //           },
      //           {
      //             call_name: 'Aiken, Linda [CHA]',
      //             direction: 'inbound',
      //             result: 'Call Cancel',
      //             path: 'pstn',
      //             callee_did_number: '+12263122126',
      //             duration: 0
      //           }
      //         ],
      //         total_calls: 2,
      //         average_call_length: 0
      //       }
      //     ],
      //     total_calls: 2,
      //     average_call_length: 0
      //   },
      //   {

      //we are building an array of objects that contain multiple levels. the first level groups the calls by week using moment starting on monday. the next level groups the calls within that week by staff member. the staff member is determined by the caller_name or callee_name field. if the caller_name or callee_name is not one of the names in the namesWeCareAbout array, then we don't  care about that call. the next level is the actual call log. the final level is the total number of calls and the average call length for that week for that staff member.
      //loop through data[0] and group the calls by week into a new object first using moment.
      //use lodash and moment to take data[0] and create an array of objects that looks like this:
      // [
      //   {
      //     week: '2021-10-04',
      //     calls: [
      //       {
      //        staff: 'Brittany Bossence',
      //        calls: [
      //          {
      //         call_name: 'Aiken, Linda [CHA]',
      //         direction: 'inbound',
      //         result: 'Call Cancel',
      //         path: 'pstn',
      //         callee_did_number: '+12263122126',
      //         duration: 0
      //       },
      //      ],
      //      average_call_length: 0,
      //      total_calls: 0
      //      },
      //      {
      //        staff: 'Alex Giannos',
      //        calls: [
      //          {
      //         call_name: 'Aiken, Linda [CHA]',
      //         direction: 'inbound',
      //         result: 'Call Cancel',
      //         path: 'pstn',
      //         callee_did_number: '+12263122126',
      //         duration: 0
      //       },
      //      ],
      //      average_call_length: 0,
      //      total_calls: 0
      //      },

      // 'Windsor' = 'Sara Taba'
      // 'London' = 'Alex Giannos'
      // 'Brantford' = 'Monica Fisher'
      // 'Chatham' = 'Brittany Bossence'
      //if callee_name is one of the above cities, then it should be added to the array of the corresponding person's calls

      const groupedByWeek = _.groupBy(data[0], (call) => {
        return moment(call.date_time).startOf("week").format("YYYY-MM-DD");
      });
      // console.log("groupedByWeek", groupedByWeek);
      const groupedByWeekAndStaff = _.map(groupedByWeek, (calls, week) => {
        const groupedByStaff = _.groupBy(calls, (call) => {
          if (namesWeCareAbout.includes(call.caller_name)) {
            return call.caller_name;
          } else if (namesWeCareAbout.includes(call.callee_name)) {
            return call.callee_name;
          }
          if (call.callee_name === "Windsor") {
            return "Sara Taba";
          } else if (call.callee_name === "London") {
            return "Mary Abel";
          } else if (call.callee_name === "Brantford") {
            // return "Monica Arthofer";
            return "Megan Behn";
          } else if (call.callee_name === "Chatham") {
            return "Brittany Bossence";
          } else if (call.callee_name === "Sarnia") {
            return "Kaylee Potts";
          } else if (call.callee_name === "Monica Fisher") {
            return "Monica Arthofer";
          }
        });
        // console.log("groupedByStaff", groupedByStaff);
        const staffArray = _.map(groupedByStaff, (calls, staff) => {
          const totalCalls = calls.length;
          const totalDuration = _.sumBy(calls, (call) => {
            return call.duration;
          });
          const averageCallLength = totalDuration / totalCalls;
          return {
            staff,
            calls,
            totalCalls,
            averageCallLength,
          };
        });
        const totalCalls = _.sumBy(staffArray, (staff) => {
          return staff.totalCalls;
        });
        const totalDuration = _.sumBy(staffArray, (staff) => {
          return staff.totalDuration;
        });
        const averageCallLength = totalDuration / totalCalls;
        return {
          week,
          staffArray,
          averageCallLength,
          totalDuration,
          totalCalls,
        };
      });
      console.log("groupedByWeekAndStaff", groupedByWeekAndStaff);
      //sort the weeks by most recent first
      groupedByWeekAndStaff.sort((a, b) => {
        return moment(b.week).diff(moment(a.week));
      });

      setGroupedZoomData(groupedByWeekAndStaff);
    }
  };

  return (
    <Box m={3} className="referralPage">
      //if callStats (object) converted to array has length //then display the
      table
      {Object.entries(callStats).length > 0 ? <div></div> : null}
      {/* {callStats && callStats.length > 0? (  <div>
            <h1>Zoom Phone Call Dashboard</h1>
            <table border="1">
                <thead>
                    <tr>
                        <th>Caller</th>
                        <th>Inbound Calls</th>
                        <th>Outbound Calls</th>
                    </tr>
                </thead>
                <tbody>
                    {callStats.map(stat => (
                        <tr key={stat.id}>
                            <td>{stat.caller_name}</td>
                            <td>{stat.inbound}</td>
                            <td>{stat.outbound}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>) : null} */}
      {groupedZoomData ? (
        // groupedZoomData contains the data we need to display the report
        //each row of the table will be a week
        //each column is a different staff member from the namesWeCareAbout array
        //each cell should contain these labels and their corresponding values from the groupedZoomData array: total calls, average call length, total duration
        //so a cell would look like:
        // Total calls: 5
        // Average call length: 10
        // Total duration: 50
        //the last row should contain the totals for each column
        //the last column should contain the totals for each row
        //the last cell should contain the total number of calls and the average call length for the entire report
        //call length should be rounded to the nearest whole number
        //the column headers need to line up with the data for that corresponding person name in the groupedZoomData array
        //if there is no data for that column, then the values should be 0, not blank

        <Box>
          <Typography variant="h4" gutterBottom>
            Zoom Report
          </Typography>

          {/* //enough filters, now display the data in a mui table */}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Week</StyledTableCell>
                  {namesWeCareAbout.map((name) => {
                    return (
                      <StyledTableCell>{name.split(" ")[0]}</StyledTableCell>
                    );
                  })}
                  <StyledTableCell>Total</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {groupedZoomData.map((week) => {
                  return (
                    <StyledTableRow>
                      <StyledTableCell>{week.week}</StyledTableCell>
                      {namesWeCareAbout.map((name) => {
                        const staff = week.staffArray.find(
                          (staff) => staff.staff === name
                        );
                        if (staff) {
                          return (
                            <StyledTableCell>
                              <Typography>
                                Total calls: {staff.totalCalls}
                              </Typography>
                              <Typography>
                                Avg length:{" "}
                                {Math.round(staff.averageCallLength)} sec
                              </Typography>
                              <Typography>
                                Total length:{" "}
                                {Math.round(
                                  (staff.totalCalls * staff.averageCallLength) /
                                    60
                                )}{" "}
                                mins
                              </Typography>
                            </StyledTableCell>
                          );
                        } else {
                          return <StyledTableCell></StyledTableCell>;
                        }
                      })}
                      <StyledTableCell>
                        <Typography>Total calls: {week.totalCalls}</Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={3}>
            <CircularProgress style={{ display: "block", margin: "0 auto" }} />
            <br />
            <Typography style={{ textAlign: "center" }}>
              Loading Zoom Report data...
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ZoomReport;
