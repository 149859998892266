import {
  Typography,
  Button,
  Grid,
  Divider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useSiteDataApi } from "../utilities/SiteDataProvider";

const Accounting = () => {
  const [submitSpinner, setSubmitSpinner] = useState(false);
  const [accountingData, setAccountingData] = useState({
    outstanding: [],
    matchedTransactions: [],
    unmatchedBills: [],
    unmatchedCredits: [],
  });
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      paddingTop: 2,
      paddingBottom: 2,
    },
  }));
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US");
  };

  const defaultValues = {
    dateRange: "2024-06", // Default for example
  };

  const { register, handleSubmit } = useForm({ defaultValues });
  const [siteDataContext] = useSiteDataApi();

  const onSubmit = async (data) => {
    setSubmitSpinner(true);

    const { manufacturer } = data;

    const res = await fetch("/api/accounting-data", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": siteDataContext.thhcsfrtoken,
      },
      body: JSON.stringify(data),
    });
    const results = await res.json();
    console.log("results", results);
    //filter the bills on the manufacturer which is Bill[x].VendorRef.name
    results.data.transactions.billsInDateRange.QueryResponse.Bill =
      results.data.transactions.billsInDateRange.QueryResponse.Bill.filter(
        (bill) => bill.VendorRef.name === manufacturer
      );
    //do the same for credits
    results.data.transactions.creditsInDateRange.QueryResponse.VendorCredit =
      results.data.transactions.creditsInDateRange.QueryResponse.VendorCredit.filter(
        (credit) => credit.VendorRef.name === manufacturer
      );

    let outstanding = results.data.outstanding || [];
    let bills =
      results.data.transactions?.billsInDateRange?.QueryResponse?.Bill || [];
    let credits =
      results.data.transactions?.creditsInDateRange?.QueryResponse
        ?.VendorCredit || [];
    console.log("outstanding 0", outstanding);

    let matchedTransactions = [];
    let unmatchedBills = [];
    let unmatchedCredits = [];

    // Matching Logic: Remove matched transactions from outstanding
    outstanding = outstanding.filter((transaction) => {
      const billMatch = bills.find(
        (bill) => bill?.DocNumber === transaction.transaction_number
      );
      const creditMatch = credits.find(
        (credit) => credit?.TxnID === transaction.transaction_number
      );

      if (billMatch || creditMatch) {
        matchedTransactions.push(transaction);
        return false; // Remove from outstanding if matched
      }
      return true;
    });

    console.log("new outstanding", outstanding);
    // Set Unmatched Bills and Credits
    unmatchedBills = bills.filter(
      (bill) =>
        !outstanding.some((trans) =>
          bill.Line.some((line) => line.DocNumber === trans.transaction_number)
        )
    );
    unmatchedCredits = credits.filter(
      (credit) =>
        !outstanding.some((trans) => credit.TxnID === trans.transaction_number)
    );
    //we need to set matched to something or it will be empty

    setAccountingData({
      outstanding,
      matchedTransactions,
      unmatchedBills,
      unmatchedCredits,
    });

    setSubmitSpinner(false);
  };

  // Helper function to deduplicate client names
  const deduplicateClientName = (clientName) => {
    if (!clientName) return "N/A";
    const uniqueNames = [...new Set(clientName.split(";"))]; // Split by ';' and deduplicate
    return uniqueNames.join(", ");
  };

  // Helper function to render unmatched bills
  const renderBillRows = (bills) => {
    if (!Array.isArray(bills) || bills.length === 0) {
      return (
        <StyledTableRow>
          <StyledTableCell colSpan={5} align="center">
            No unmatched bills found.
          </StyledTableCell>
        </StyledTableRow>
      );
    }

    return bills.map((bill, index) => {
      const line = bill.Line[0]; // Assuming the first line item
      return (
        <StyledTableRow key={index}>
          <StyledTableCell>
            {bill.TxnDate ? formatDate(bill.TxnDate) : "N/A"}
          </StyledTableCell>
          <StyledTableCell>{bill.DocNumber || "N/A"}</StyledTableCell>{" "}
          {/* Invoice */}
          <StyledTableCell sx={{ maxWidth: "200px" }}>
            {line?.Description || "N/A"}
          </StyledTableCell>
          {/* Description */}
          <StyledTableCell>
            {line?.AccountBasedExpenseLineDetail?.CustomerRef?.name || "N/A"}
          </StyledTableCell>{" "}
          {/* Patient */}
          <StyledTableCell>
            {currencyFormatter.format(bill.TotalAmt) || "N/A"}
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  };

  // Helper function to render unmatched credits
  const renderCreditRows = (credits) => {
    if (!Array.isArray(credits) || credits.length === 0) {
      return (
        <StyledTableRow>
          <StyledTableCell colSpan={5} align="center">
            No unmatched credits found.
          </StyledTableCell>
        </StyledTableRow>
      );
    }

    return credits.map((credit, index) => (
      <StyledTableRow key={index}>
        <StyledTableCell>
          {credit.TxnDate ? formatDate(credit.TxnDate) : "N/A"}
        </StyledTableCell>
        {/* Date */}
        <StyledTableCell>{credit.DocNumber || "N/A"}</StyledTableCell>
        {/* Invoice */}
        <StyledTableCell sx={{ maxWidth: "200px" }}>
          {credit.PrivateNote || "N/A"}
        </StyledTableCell>
        {/* Description */}
        <StyledTableCell>{credit.VendorName || "N/A"}</StyledTableCell>
        {/* Vendor/Patient */}
        <StyledTableCell>
          {currencyFormatter.format(credit.Balance) || "N/A"}
        </StyledTableCell>
        {/* Amount */}
      </StyledTableRow>
    ));
  };

  // Helper function to render outstanding transactions
  const renderOutstandingRows = (outstanding) => {
    if (!Array.isArray(outstanding) || outstanding.length === 0) {
      return (
        <StyledTableRow>
          <StyledTableCell colSpan={5} align="center">
            No outstanding transactions found.
          </StyledTableCell>
        </StyledTableRow>
      );
    }

    return outstanding.map((transaction, index) => (
      <StyledTableRow key={index}>
        <StyledTableCell>
          {transaction.transaction_date
            ? formatDate(transaction.transaction_date)
            : "N/A"}
        </StyledTableCell>
        {/* Date */}
        <StyledTableCell>
          {transaction.transaction_number || "N/A"}
        </StyledTableCell>
        <StyledTableCell sx={{ maxWidth: "200px" }}>
          {transaction.description || "N/A"}
        </StyledTableCell>
        <StyledTableCell>
          {deduplicateClientName(transaction.client_name) || "N/A"}
        </StyledTableCell>
        <StyledTableCell>
          {currencyFormatter.format(transaction.amount) || "N/A"}
        </StyledTableCell>
      </StyledTableRow>
    ));
  };

  // Helper function to render matched transactions
  const renderMatchedTransactionRows = (matchedTransactions) => {
    if (
      !Array.isArray(matchedTransactions) ||
      matchedTransactions.length === 0
    ) {
      return (
        <StyledTableRow>
          <StyledTableCell colSpan={5} align="center">
            No matched transactions found.
          </StyledTableCell>
        </StyledTableRow>
      );
    }

    return matchedTransactions.map((transaction, index) => (
      <StyledTableRow key={index}>
        <StyledTableCell>
          {transaction.transaction_date
            ? formatDate(transaction.transaction_date)
            : "N/A"}
        </StyledTableCell>
        <StyledTableCell>
          {transaction.transaction_number || "N/A"}
        </StyledTableCell>
        <StyledTableCell sx={{ maxWidth: "200px" }}>
          {transaction.description || "N/A"}
        </StyledTableCell>
        <StyledTableCell>
          {deduplicateClientName(transaction.client_name) || "N/A"}
        </StyledTableCell>
        <StyledTableCell>
          {currencyFormatter.format(transaction.amount) || "N/A"}
        </StyledTableCell>
      </StyledTableRow>
    ));
  };

  return (
    <Grid container>
      <Grid m={3} item p={1}>
        {/* Form for Date Range Submission */}
        <form autoComplete="on" onSubmit={handleSubmit(onSubmit)}>
          <Typography
            textAlign="center"
            variant="h4"
            gutterBottom
            style={{ marginBottom: "30px" }}
          >
            Accounting Reconciliation
          </Typography>

          <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={2}></Grid>
            <Grid
              container
              justifyContent="center" // Centers items horizontally
              alignItems="center" // Centers items vertically (optional)
              direction="column" // Stacks items vertically
              style={{ textAlign: "center", width: "100%" }} // Ensures centering
            >
              <Grid item xs={12} sm={6}>
                {" "}
                {/* Adjust width of form */}
                <TextField
                  label="Enter the date range (YYYY-MM)"
                  {...register("dateRange")}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    inputProps: { style: { textAlign: "center" } }, // Center the text inside the input field
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginTop: "16px" }}>
                <select
                  {...register("manufacturer")}
                  style={{
                    width: "100%",
                    padding: "12px", // More padding for a better look
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    fontSize: "16px",
                  }}
                >
                  <option value="ReSound Corporation">
                    ReSound Corporation
                  </option>
                  <option value="Starkey">Starkey</option>
                </select>
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginTop: "16px" }}>
                <Button
                  variant="contained"
                  type="submit"
                  style={{ width: "100%" }}
                  disabled={submitSpinner}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Grid
          container
          spacing={3}
          mt={3}
          alignItems="center"
          justifyContent="center"
        >
          {/* Matched Transactions */}
          <Typography variant="h6" mt={3}>
            Matched Transactions
          </Typography>
          <Divider />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>Invoice</StyledTableCell>
                  <StyledTableCell sx={{ maxWidth: "200px" }}>
                    Description
                  </StyledTableCell>
                  <StyledTableCell>Patient</StyledTableCell>
                  <StyledTableCell>Amount</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {renderMatchedTransactionRows(
                  accountingData.matchedTransactions
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Outstanding Transactions */}
          <Typography variant="h6" mt={3}>
            Outstanding Transactions
          </Typography>
          <Divider />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>Invoice</StyledTableCell>
                  <StyledTableCell sx={{ maxWidth: "200px" }}>
                    Description
                  </StyledTableCell>
                  <StyledTableCell>Patient</StyledTableCell>
                  <StyledTableCell>Amount</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {renderOutstandingRows(accountingData.outstanding)}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Unmatched Bills */}
          <Typography variant="h6" mt={3}>
            Unmatched Bills
          </Typography>
          <Divider />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>Invoice</StyledTableCell>
                  <StyledTableCell sx={{ maxWidth: "200px" }}>
                    Description
                  </StyledTableCell>
                  <StyledTableCell>Patient</StyledTableCell>
                  <StyledTableCell>Amount</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {renderBillRows(accountingData.unmatchedBills)}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Unmatched Credits */}
          <Typography variant="h6" mt={3}>
            Unmatched Credits
          </Typography>
          <Divider />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>Invoice</StyledTableCell>
                  <StyledTableCell sx={{ maxWidth: "200px" }}>
                    Description
                  </StyledTableCell>
                  <StyledTableCell>Patient</StyledTableCell>
                  <StyledTableCell>Amount</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {renderCreditRows(accountingData.unmatchedCredits)}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Accounting;
